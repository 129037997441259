import React, { Component } from "react";

import ClientAPI from "../service/ClientAPI";
import AdminAPI from "../service/AdminAPI";
import Searchbar from "../components/Searchbar";
import LocalStorageManager from "../managers/LocalStorageManager";
import AllProductsTable from "../components/AllProductsTable";
import CreateProductPopup from "../components/CreateProductPopup";

class DashboardAlleProdukte extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            allOrders: [],
            searchUserInTable: null,
            searchProduct: null,
            searchUser: null,
            userOrders: [],
            createProduct: false,
            createUser: false,
            showDateTable: false,
            updateStartDate: false,
            updateEndDate: false,
            startDate: props.start,
            endDate: props.end,
            downloadingExcel: false
        };

        this.isAdmin = LocalStorageManager.Get("admin") === "1";
    }

    componentDidMount() {
        if (!this.isAdmin) {
            ClientAPI.GetOrders()
                .then((_orders) => {
                    this.setState({ userOrders: _orders });
                })
                .catch(console.error);

            return;
        }

        ClientAPI.RequestProducts()
            .then((_data) => {
                AdminAPI.FetchUserOrders()
                    .then(_allOrders => {
                        ClientAPI.GetOrders()
                            .then(_orders => {
                                this.setState({ products: _data, allOrders: _allOrders, userOrders: _orders })
                            })
                    })
                    .catch(console.error);
            })
            .catch(console.error);
    }

    render() {
        return (
                <div>
                    <h3 id="products" style={{ marginBottom: "15px", textAlign: "center", marginTop: "20px", marginBottom: "15px" }}>Übersicht aller Produkte</h3>
                    <div style={{ maxWidth: "95%", display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto", marginTop: "10px" }}>
                        <Searchbar addButton={true} onAdd={() => this.setState({ createProduct: true })} label={"Suche nach Produkt (Name)"} searchRequest={(_searchStr) => this.setState({ searchProduct: _searchStr })} />
                        <AllProductsTable maxProducts={this.state.products.length} products={this.state.products.filter(_product => this.state.searchProduct ? `${_product.Name}`.toLowerCase().includes(this.state.searchProduct.toLowerCase()) : true)} />
                    </div>
                    <CreateProductPopup show={this.state.createProduct} products={this.state.products} onClose={() => this.setState({ createProduct: false })} />
                </div>
        )
    }
}

export default DashboardAlleProdukte;