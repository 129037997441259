import React, { Component } from "react";

import ClientAPI from "../service/ClientAPI";
import AdminAPI from "../service/AdminAPI";
import LocalStorageManager from "../managers/LocalStorageManager";
import MyProductsTable from "../components/MyProductsTable";

import 'react-calendar/dist/Calendar.css';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            allOrders: [],
            searchUserInTable: null,
            searchProduct: null,
            searchUser: null,
            userOrders: [],
            showDateTable: false,
            updateStartDate: false,
            updateEndDate: false,
            startDate: props.start,
            endDate: props.end,
            downloadingExcel: false
        };

        this.isAdmin = LocalStorageManager.Get("admin") === "1";
    }

    componentDidMount() {
        if (!this.isAdmin) {
            ClientAPI.GetOrders()
                .then((_orders) => {
                    this.setState({ userOrders: _orders });
                })
                .catch(console.error);

            return;
        }

        ClientAPI.RequestProducts()
            .then((_data) => {
                AdminAPI.FetchUserOrders()
                    .then(_allOrders => {
                        ClientAPI.GetOrders()
                            .then(_orders => {
                                this.setState({ products: _data, allOrders: _allOrders, userOrders: _orders })
                            })
                    })
                    .catch(console.error);
            })
            .catch(console.error);
    }

    RenderProductsOfUser = (admin = false) => {
        return (
            admin ?
                (
                    <div style={{ maxWidth: "95%", margin: "auto" }}>
                        <h3 id="myorders" style={{ textAlign: "center", marginTop: "20px" }}>Meine Bestellungen</h3>
                        <br />
                        <MyProductsTable orders={this.state.userOrders} />
                    </div>
                )
                :
                (
                    <div style={{ minHeight: "100vh", maxWidth: "95%", margin: "auto" }}>
                        <h3 id="myorders" style={{ textAlign: "center", marginTop: "20px" }}>Meine Bestellungen</h3>
                        <br />
                        <MyProductsTable orders={this.state.userOrders} />
                    </div>
                )
        );
    }

    render() {
        return (
            this.isAdmin ? (
                <div style={{ minHeight: "95vh" }}>
                    {this.RenderProductsOfUser(true)}
                </div>
            )
                :
                <div style={{ minHeight: "95vh", marginTop: "50px" }}>
                    <div style={{ margin: "auto", maxWidth: "95%" }}>
                        <p><b>Hinweis:</b> Nachdem Sie Bestellungen im Warenkorb hinzugefügt haben und mit dieser fertig sind, müssen Sie ihre <b>Bestellung bestätigen</b>. Ansonsten wird ihre Bestellung <b>nicht als komplett vermerkt und nicht berücksichtigt bei der Abholung</b>. <b>Achtung:</b> Sobald Sie die Bestellung für ein Produkt bestätigt haben, <b>können Sie keine weiteren dieser Art hinzufügen!</b></p>
                        <p>Um die Bestlleung zu bestätigen müssen Sie unten in der Tabelle eine Bestellung anwählen und dann sollte im Tabellenkopf ein neuer Knopf auftauchen ("Bestätigen"), welcher gedrückt werden muss. Es können auch mehrere Bestellungen selektiert werden. Ausgegraute Einträge wurden bereits von Ihnen bestätigt und können nicht mehr selektiert werden.</p>
                        <hr />
                    </div>
                    {this.RenderProductsOfUser()}
                </div>
        )
    }
}

export default Dashboard;